import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import figmaImage from '../assets/images/resources/figma.svg';
// import smsImage from '../assets/images/resources/sms.svg';
import { ReactComponent as SMSImage } from '../assets/images/resources/sms.svg';
// import abstractBackgroundImage from '../assets/images/resources/abstract-background.png';
import videoPlayerImage from '../assets/images/resources/video-player.svg';
import { ReactComponent as PaymentImage } from '../assets/images/resources/payment.svg';
import Integration from './integration';
import { Connectors } from './connectors';
import { PhonePad } from './phone-pad';

const resources = [
  {
    title: 'No Code',
    description:
      'Build your USSD app through an intuitive, userfriendly interface with no code.',
    image: function FigmaImage() {
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          {/* <img
            className="absolute inset-0 h-full w-full object-cover"
            src={abstractBackgroundImage}
            alt=""
            sizes="(min-width: 1280px) 21rem, (min-width: 1024px) 33vw, (min-width: 768px) 19rem, (min-width: 640px) 50vw, 100vw"
          /> */}
          <img className="relative" src={videoPlayerImage} alt="" />
        </div>
      );
    },
  },
  {
    title: 'Short Codes',
    description:
      'Acquire and manage your dedicated and shared short codes across all networks. No trips to regulatory bodies or mobile network operators.',
    image: function VideoPlayerImage() {
      return (
        <MotionCard className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <div className="flex size-full">
            <PhonePad highlighted={['R', 'W', 'D']} />
          </div>
        </MotionCard>
      );
    },
  },
  {
    title: 'Connectors',
    description:
      "Don't have your USSD code with us? No problem. Connect to your app from any USSD code provider.",
    image: function FigmaImage() {
      return (
        <MotionCard className="">
          <Connectors />
        </MotionCard>
      );
    },
  },
  {
    title: 'Payments',
    description: 'Receive payments directly from your customers.',
    image: function VideoPlayerImage() {
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          {/* <img
            src={paymentImage}
            alt=""
            className="w-36 fill-blue-400"
          /> */}
          <PaymentImage className="text-[#6366f1] size-36" />
        </div>
      );
    },
  },
  {
    title: 'Integration',
    description:
      'Link your application with your existing software tools and services via APIs.',
    image: function DiscordImage() {
      return (
        <MotionCard className="z-10 !overflow-visible bg-[radial-gradient(#ffffff_5%,#ffffff)] ring-white/15">
          <Integration />
        </MotionCard>
      );
    },
  },
  {
    title: 'Send SMS',
    description: 'Send SMS to your customers through your USSD app.',
    image: function DiscordImage() {
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          {/* <img src={smsImage} alt="" /> */}
          <SMSImage />
        </div>
      );
    },
  },
];

export function UzoFeaturesBlocks() {
  return (
    <section
      id="resources"
      aria-labelledby="resources-title"
      className="relative overflow-hidden bg-secondary pb-28 pt-20 sm:py-32 scroll-mt-14 py-16 sm:scroll-mt-32"
    >
      <div className="container relative">
        <div className="max-w-5xl md:mx-auto md:text-center">
          <h2 className="text-6xl font-medium tracking-tight bg-gradient-to-b from-yellow to-orange bg-clip-text text-transparent">
            {/* Now is the time to build. */}
            Unlock the Power of USSD with Uzo!
          </h2>
          <p className="mt-2 text-[20px] tracking-tight text-gray-600 px-8">
            {/* Uzo provides you with an easy to use interface to create your own USSD application without any prior coding experience. */}
            Transform your ideas into reality with our no-code
            platform. With Uzo, effortlessly create your own USSD
            applications and secure your unique USSD codes—all without
            writing a single line of code
          </p>
        </div>
        <div className="mt-16 mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-7xl">
          <ol className="-mx-3 grid grid-cols-1 gap-y-16 lg:grid-cols-3 lg:text-center xl:-mx-12 xl:divide-x xl:divide-slate-400/20">
            {resources.map((resource) => (
              <li
                key={resource.title}
                className="grid auto-rows-min grid-cols-1 items-center gap-12 px-3 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-1 xl:px-12 [&:nth-child(4)]:lg:!border-l-0"
              >
                <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                  <resource.image />
                </div>
                <div>
                  <h3 className="text-lg font-bold tracking-tight text-slate-900">
                    {resource.title}
                  </h3>
                  <p className="mt-2 text-md text-slate-600">
                    {resource.description}
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>

      <div className="container">
        <div className="mt-20 flex justify-center">
          <Link
            to="https://app.uzoussd.com/app/register"
            className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow via-primary to-orange600"
          >
            Let's Build
          </Link>
        </div>
      </div>
    </section>
  );
}

function MotionCard({
  className = '',
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <motion.div
      initial="idle"
      whileHover="active"
      variants={{ idle: {}, active: {} }}
      className={clsx(
        className,
        'group relative flex flex-col overflow-hidden rounded-lg',
        'bg-white shadow-sm ring-1 ring-black/5',
        'data-[dark]:bg-gray-800 data-[dark]:ring-white/15'
      )}
    >
      <div className="relative h-48 shrink-0 sm:h-60 lg:h-40">
        {children}
      </div>
    </motion.div>
  );
}
