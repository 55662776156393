import { useId } from 'react'
import clsx from 'clsx'


export function CheckIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 32 32" {...props}>
      <path d="M11.83 15.795a1 1 0 0 0-1.66 1.114l1.66-1.114Zm9.861-4.072a1 1 0 1 0-1.382-1.446l1.382 1.446ZM14.115 21l-.83.557a1 1 0 0 0 1.784-.258L14.115 21Zm.954.3c1.29-4.11 3.539-6.63 6.622-9.577l-1.382-1.446c-3.152 3.013-5.704 5.82-7.148 10.424l1.908.598Zm-4.9-4.391 3.115 4.648 1.661-1.114-3.114-4.648-1.662 1.114Z" />
    </svg>
  )
}

export function GridPattern(
  props: Omit<React.ComponentPropsWithoutRef<'pattern'>, 'id'>,
) {
  let patternId = useId()

  return (
    <svg aria-hidden="true" className="absolute inset-0 h-full w-full">
      <defs>
        <pattern
          id={patternId}
          width="128"
          height="128"
          patternUnits="userSpaceOnUse"
          {...props}
        >
          <path d="M0 128V.5H128" fill="none" stroke="currentColor" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill={`url(#${patternId})`} />
    </svg>
  )
}


function Plan({
  name,
  description,
  features,
  featured = false,
}: {
  name: string
  description: string
  features: Array<{name: string, price: string, period: string}>
  featured?: boolean
}) {
  return (
    <div
      className={clsx(
        'relative px-4 py-16 sm:rounded-5xl sm:px-10 md:py-12 lg:px-12',
        featured && 'bg-gray-900 sm:shadow-lg',
      )}
    >
      {featured && (
        <div className="absolute inset-0 text-white/10 [mask-image:linear-gradient(white,transparent)]">
          <GridPattern x="50%" y="50%" />
        </div>
      )}
      <div className="relative flex flex-col">
        {/* <h3
          className={clsx(
            'mt-7 text-lg font-semibold tracking-tight',
            featured ? 'text-white' : 'text-slate-900',
          )}
        >
          {name}
        </h3> */}
        <p
          className={clsx(
            'mt-2 text-lg tracking-tight',
            featured ? 'text-white' : 'text-slate-600',
          )}
        >
          {description}
        </p>
        <p className="order-first flex font-display font-bold">
          {/* <span
            className={clsx(
              'text-[1.75rem] leading-tight',
              featured ? 'text-blue-200' : 'text-slate-500',
            )}
          >
            ¢
          </span> */}
          <span
            className={clsx(
              'mt-1 text-4xl tracking-tight',
              featured ? 'text-white' : 'text-slate-900',
            )}
          >
            {name}
          </span>
        </p>
        <div className="order-last mt-8">
          <ul
            className={clsx(
              '-my-2 divide-y text-base tracking-tight',
              featured
                ? 'divide-white/10 text-white'
                : 'divide-slate-200 text-slate-900',
            )}
          >
            {Object.values(features).map((feature) => (
              <li key={feature.name} className="flex items-center py-2">
                <CheckIcon
                  className={clsx(
                    'h-8 w-8 flex-none',
                    featured ? 'fill-white' : 'fill-slate-600',
                  )}
                />
                <span className="ml-2 me-3">{feature.name}</span>

                <div className="flex items-center gap-4">
                  <p className={clsx('flex text-4xl font-medium', featured ? 'text-white' : 'text-gray-950')}>
                    <span
                      className={clsx(
                        'text-[1.75rem] leading-tight me-1',
                        featured ? 'text-blue-200' : 'text-slate-500',
                      )}
                    >
                      ¢
                    </span>
                    {feature.price}
                  </p>
                  <div className={clsx('text-sm/5', featured ? 'text-white' : 'text-gray-950/75')}>
                    {/* <p>GHs</p> */}
                    <p>per month</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <Button
          href={href}
          color={featured ? 'white' : 'slate'}
          className="mt-8"
          aria-label={`Get started with the ${name} plan for $${price}`}
        >
          Get started
        </Button> */}
      </div>
    </div>
  )
}

export function PricingPairs() {
  const p = Array.from({ length: 1 }, () => []);
  return (
    <div className='grid grid-cols-1'>
      {p.map(() =>

      <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="scroll-mt-14 pb-8 pt-16 sm:scroll-mt-32 sm:pb-10 sm:pt-20 lg:pb-2"
    >
      <div className="mx-auto max-w-5xl lg:px-6">
        <div className="grid bg-slate-50 sm:px-6 sm:pb-16 md:grid-cols-2 md:rounded-6xl md:px-8 md:pt-16 lg:p-20">
          <Plan
            name="Standard Code"
            description="designed for convenient and cost-effective access to services."
            features={[
              { name: '2 Digits', price: '350', period: 'monthly' },
              { name: '3 Digits', price: '250', period: 'monthly' },
              {name: '4 Digits', price: '200', period: 'monthly'},
            ]}
          />
          <Plan
            featured
            name="Premium Code"
            description="greater memorability and prestige for an enhanced user experience."
                features={[
              { name: '1 Digits', price: '600', period: 'monthly' },
              { name: '2 Digits', price: '450', period: 'monthly' },
                  { name: '3 Digits', price: '235', period: 'monthly' },
              {name: '4 Digits', price: '300', period: 'monthly'},
            ]}
          />
        </div>
      </div>
      </section>
      )}
    </div>
  )
}
