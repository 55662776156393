import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import SlackIcon from '../assets/images/resources/slack.svg';

function Circle({
  size,
  delay,
  opacity,
}: {
  size: number;
  delay: number;
  opacity: string;
}) {
  return (
    <motion.div
      animate={{
        width: [`${size}px`, `${size + 10}px`, `${size}px`],
        height: [`${size}px`, `${size + 10}px`, `${size}px`],
        transition: {
          duration: 0.75,
          repeat: Infinity,
          repeatDelay: 1.25,
          ease: 'easeInOut',
          delay,
        },
      }}
      style={{ '--opacity': opacity } as React.CSSProperties}
      className={clsx(
        'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full',
        'bg-[radial-gradient(circle,transparent_25%,color-mix(in_srgb,_theme(colors.blue.500)_var(--opacity),transparent)_100%)]',
        'ring-1 ring-inset ring-blue-500/[8%]'
      )}
    />
  );
}

function Circles() {
  return (
    <div className="absolute inset-0">
      <Circle size={330} opacity="3%" delay={0.45} />
      <Circle size={260} opacity="5%" delay={0.3} />
      <Circle size={180} opacity="5%" delay={0.15} />
      <Circle size={110} opacity="10%" delay={0} />
      <div className="absolute inset-0 bg-gradient-to-t from-white to-35%" />
    </div>
  );
}

function MainLogo() {
  return (
    <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 flex size-12 items-center justify-center rounded-full bg-white text-primary font-bold shadow ring-1 ring-black/5">
      {/* <Mark className="h-9 fill-black" /> */} Uzo
    </div>
  );
}

function Logo({
  src,
  left,
  top,
  hover,
}: {
  src: string;
  left: number;
  top: number;
  hover: { x: number; y: number; rotate: number; delay: number };
}) {
  return (
    <motion.div
      animate={{
        x: [0, hover.x, 0],
        y: [0, hover.y, 0],
        rotate: [0, hover.rotate, 0],
        transition: {
          duration: 0.75,
          repeat: Infinity,
          repeatDelay: 1.25,
          ease: 'easeInOut',
          delay: hover.delay,
        },
      }}
      style={{ left, top } as React.CSSProperties}
      className="absolute size-10 rounded-full bg-white shadow ring-1 ring-black/5 flex justify-center items-center"
    >
      <img
        alt=""
        src={src}
        style={{ left, top } as React.CSSProperties}
        className="size-6"
      />
    </motion.div>
  );
}

export function Connectors() {
  return (
    <div
      aria-hidden="true"
      className="relative h-full overflow-hidden"
    >
      <Circles />
      <div className="absolute left-1/2 h-full w-full -translate-x-1/2">
        <MainLogo />

        <Logo
          src={SlackIcon}
          left={255}
          top={10}
          hover={{ x: 4, y: -5, rotate: 6, delay: 0.3 }}
        />
        <Logo
          src={SlackIcon}
          left={255}
          top={80}
          hover={{ x: 3, y: 5, rotate: 7, delay: 0.2 }}
        />
        <Logo
          src={SlackIcon}
          left={144}
          top={-6}
          hover={{ x: -2, y: -5, rotate: -6, delay: 0.15 }}
        />
        <Logo
          src={SlackIcon}
          left={36}
          top={56}
          hover={{ x: -4, y: -5, rotate: -6, delay: 0.35 }}
        />
        <Logo
          src={SlackIcon}
          left={116}
          top={133}
          hover={{ x: -3, y: 5, rotate: 3, delay: 0.15 }}
        />
      </div>
    </div>
  );
}
