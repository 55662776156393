import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
// import { Link } from "react-router-dom";
// import FaqItem from "../components/FaqItem";
// import QuestionData from '../assets/data/faq.json';
import { Hero } from "../components/hero";
import CallToAction from "../components/call-to-action";
// import { USSDFeatures } from "../components/ussd-features";
// import { UzoFeatures } from "../components/uzo-features";
import { Faqs } from "../components/faqs";
import { UzoFeaturesBlocks } from "../components/uzo-features-blocks";
// import { UzoFeaturesSlide } from "../components/uzo-features-slide";

export default function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <Hero />
      {/* <Section1 /> */}
      {/* <USSDFeatures /> */}
      <UzoFeaturesBlocks />
      {/* <UzoFeaturesSlide /> */}
      {/* <UzoFeatures /> */}
      <CallToAction />
      {/* <Section2 /> */}
      {/* <Section3 /> */}
      <Faqs />
      {/* <Faq /> */}
      <Section5 />
      <Footer />
    </>
  )
}

// const Section1 = () => {
//   return (
//     <div className="bg-secondary">
//       <div className="relative overflow-clip overflow-hidden">
//         <div className="container mx-auto py-24">
//           <div className="flex flex-col-reverse md:flex-row items-center justify-center space-x-8">
//             <div className="basis-1/2 text-center md:text-left px-4 mt-4 md:mt-0">
//               <div className="font-bold text-[52px] leading-tight">
//                 USSD apps empower businesses!
//               </div>
//               <div className="text-[22px] leading-wide">

// 				Never miss a beat with your business. Connect with customers from any phone, smart or not.<br/>
//                 <br/>
//                 Own one now with just a few clicks. <br/>No technical skills needed.
//               </div>
//               <div className="mt-12">
//                 <Link to='https://app.uzoussd.com/app/register' className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow to-orange">
//                   Get Your Free Account
//                 </Link>
//               </div>
//             </div>
//             <div className="basis-1/2">
//               <img src='/images/walkthrough.png' alt='' />
//             </div>
//           </div>
//         </div>

//         {/* <div className="absolute top-0 left-0 h-full -z-10">
//           <div className="rounded-r-full bg-primary absolute md:h-96 md:w-80 md:-left-56 h-48 w-48 -left-32 top-8" />
//         </div>

//         <div className="absolute top-0 right-0 h-full -z-10">
//           <div className="rounded-l-full h-full w-96 bg-primary -right-52 absolute" />
//           <div className="rounded-l-full h-5/6 w-80 bg-white -right-56 top-16 absolute" />
//         </div> */}
//       </div>
//     </div>
//   )
// }

// const Section2 = () => {
//   return (
//     <div className="bg-white">
//       <div className="container mx-auto py-20 sm:py-32">
//         <div className="md:flex flex-row items-center">
//           <div className="basis-1/2 px-4">
//             <img src='/images/build.png' alt='' />
//           </div>
//           <div className="basis-1/2 text-center md:text-left px-4 mt-4 md:mt-0">
//             <div className="font-bold text-[40px] leading-tight bg-gradient-to-b from-yellow to-orange bg-clip-text text-transparent">
//               Build your own USSD App with a few clicks
//             </div>
//             <div className="text-[18px] leading-tight mt-4 md:pr-16">
//               Unlock the power of USSD with ease! Whether you're an enterprise, an entrepreneur, developer, or tech enthusiast, Uzo provides you with an easy to use interface to create your own USSD application without any prior coding experience.
//             </div>
//             <div className="mt-12">
//               <Link to='https://app.uzoussd.com/app/register' className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow via-primary to-orange600">
//                 Build an App
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// const Section3 = () => {
//   return (
//     <div className="bg-white">
//       <div className="container mx-auto pt-8 pb-24">
//         <div className="flex flex-col-reverse md:flex-row items-center justify-center">
//           <div className="text-center md:text-left basis-1/2 px-4 mt-4 md:mt-0">
//             <div className="font-bold text-[40px] leading-tight bg-gradient-to-b from-yellow to-orange bg-clip-text text-transparent">
//               Get your short code
//             </div>
//             <div className="text-[18px] leading-tight mt-4 md:pr-16">
//               Acquire and manage your dedicated and shared short codes across all networks. No trips to regulatory bodies or mobile network operators.
//             </div>
//             <div className="mt-12">
//               <Link to='https://app.uzoussd.com/app/register' className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow via-primary to-orange600">
//                 Get Code
//               </Link>
//             </div>
//           </div>
//           <div className="basis-1/2 px-4">
//             <img src='/images/ussdcode.png' alt='' />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// const Faq = () => {
//   const [expandedIndex, setExpandedIndex] = React.useState(-1);
//   const toggleFaq = (index: number) => {
//     setExpandedIndex(index === expandedIndex ? -1 : index);
//   }

//   return (
//     <div className="bg-lightblue" id="faq">
//       <div className="pt-12 pb-28 relative rounded-xl overflow-auto">
//         <div className="font-bold text-[40px] text-center">
//           Frequently Asked Questions
//         </div>

//         <div className="my-8 flex justify-center">
//           <div className='max-w-4xl'>
//             <div className="flex md:flex-row flex-col">
//               <div className='mx-2 flex-1 flex-grow'>
//                 <FaqItem {...QuestionData[0]} isExpanded={expandedIndex === 0} onClick={() => toggleFaq(0)} />
//               </div>

//               <div className='mx-2 flex-1 flex-grow'>
//                 <FaqItem {...QuestionData[1]} isExpanded={expandedIndex === 1} onClick={() => toggleFaq(1)} />
//               </div>
//             </div>
//             <div className="flex md:flex-row flex-col">
//               <div className='mx-2 flex-1 flex-grow'>
//                 <FaqItem {...QuestionData[2]} isExpanded={expandedIndex === 2} onClick={() => toggleFaq(2)} />
//               </div>

//               <div className='mx-2 flex-1 flex-grow'>
//                 <FaqItem {...QuestionData[3]} isExpanded={expandedIndex === 3} onClick={() => toggleFaq(3)} />
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="text-center">
//           Have more questions? <a href='/faq' className="text-primary">See the full FAQ</a>
//         </div>
//       </div>
//     </div>
//   )
// }

const Section5 = () => {
  const customers = [
    {
      image: '/images/fido.png',
      name: 'Fido'
    },
    {
      image: '/images/fastcare.png',
      name: 'Fastcare'
    },
    {
      image: '/images/nationwidemh.png',
      name: 'Nationwide'
    },
  ];
  return (
    <div className="bg-white">
      <div className="py-24 relative rounded-xl overflow-auto">
        <div className="font-bold text-[40px] text-center">
          Some Clients Who Trust Us
        </div>

        <div className="relative overflow-auto">
          <div className="container mx-auto max-w-md">
            <div className="overflow-x-auto flex justify-between">
              {customers.map(customer => (
                <div className="py-6 px-3 first:pl-6 last:pr-6">
                  <img className="max-w-24" src={customer.image} alt={customer.name}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
